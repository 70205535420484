<template>
  <v-row>
    <v-col cols="12" class="py-0">
      <h1 class="font-weight-light mb-0">Page {{ $route.path }}...</h1>
      <span class="overline">Add other dashboard views</span>
    </v-col>
  </v-row>
</template>

<script>
export default {
  template: "#page",
};
</script>
